import React from "react";
import { Route, useHistory } from "react-router-dom";

import strutture, { SetStrutture, Tipologia } from "../data/strutture";
import consonanti from "../data/tabella-consonanti.json";
import vocali from "../data/vocali.json";

import { salvaTraining, TrainingData } from "../training/index";

import { v4 as uuid } from "uuid";
import { useState } from "react";

export default function Setup() {
  const [struttureSelezionate, aggiornaStruttureSelezionate] = useState<
    string[]
  >([]);

  function manageStrutturaSelezionata(
    strutturaCliccata: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.checked) {
      aggiornaStruttureSelezionate(function (oldState) {
        return [...oldState, strutturaCliccata];
      });
    } else {
      aggiornaStruttureSelezionate(
        struttureSelezionate.filter(function (struttura) {
          return struttura !== strutturaCliccata;
        })
      );
    }
  }

  function manageSelezionaTuttaTipologiaDiStrutture(tipologia: Tipologia) {
    aggiornaStruttureSelezionate(function (oldState) {
      return [...oldState, ...strutture[tipologia]];
    });
  }

  const [fonemiSelezionati, aggiornaFonemiSelezionati] = useState<string[]>([]);

  function manageFonemaSelezionato(
    fonemaCliccato: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (event.target.checked) {
      aggiornaFonemiSelezionati(function (oldState) {
        return [...oldState, fonemaCliccato];
      });
    } else {
      aggiornaFonemiSelezionati(
        fonemiSelezionati.filter(function (struttura) {
          return struttura !== fonemaCliccato;
        })
      );
    }
  }

  function selezionaTutteVocali() {
    aggiornaFonemiSelezionati(function (oldState) {
      return [...oldState, ...vocali];
    });
  }

  function selezionaTutteTipologiaPronuncia(tipologia: string) {
    aggiornaFonemiSelezionati(function (oldState) {
      let fonemiDaAggiungere: string[] = [];

      // @ts-ignore
      const p = consonanti[tipologia];

      for (let key in p) {
        fonemiDaAggiungere = fonemiDaAggiungere.concat(p[key]);
      }

      return [...oldState, ...fonemiDaAggiungere];
    });
  }

  function selezionaTuttaTipologia(tipologia: string) {
    aggiornaFonemiSelezionati(function (oldState) {
      let fonemiDaAggiungere: string[] = [];

      for (let key in consonanti) {
        fonemiDaAggiungere = fonemiDaAggiungere.concat(
          // @ts-ignore
          consonanti[key][tipologia]
        );
      }

      return [...oldState, ...fonemiDaAggiungere];
    });
  }

  const [tipologiaParole, aggiornaTipologiaParole] = useState<number>(0);
  const [includiGeminate, aggiornaIncludiGeminate] = useState<boolean>(false);

  const [eserciziDaCompletare, aggiornaEserciziDaCompletare] =
    useState<number>(10);

  const [sfondo, aggiornaSfondo] = useState<string>("nuvole.jpg");

  const history = useHistory();

  const [errore, mostraErrore] = useState<string | null>(null);

  const checkAndSubmit = () => {
    const trainingData: TrainingData = {
      id: uuid(),
      created: new Date().getTime(),
      struttureSelezionate,
      fonemiSelezionati,

      tipologiaParole,
      includiGeminate,

      sfondo,

      eserciziDaCompletare,
      eserciziCompletati: 0,
    };

    if (!struttureSelezionate.length) {
      return mostraErrore("Seleziona almeno una struttura sillabica!");
    }

    if (!fonemiSelezionati.length) {
      return mostraErrore("Seleziona almeno un fonema!");
    }

    salvaTraining(trainingData);

    history.push(`/training?id=${trainingData.id}`);
  };

  return (
    <div className="container">
      <div>
        <h2>Configura esercitazione</h2>
      </div>

      <form className="col">
        <div className="row">
          <h3>Strutture sillabiche</h3>

          {(Object.keys(strutture) as Tipologia[]).map(
            (nomeTipologia: Tipologia, index) => {
              return (
                <div className="col" key={index}>
                  <div>
                    <label htmlFor={index.toString()}>
                      <h4>{capitalizeFirstLetter(nomeTipologia)}</h4>
                    </label>
                    <br />
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        manageSelezionaTuttaTipologiaDiStrutture(nomeTipologia);
                      }}
                    >
                      Seleziona tutti
                    </button>
                  </div>

                  {strutture[nomeTipologia].map((struttura: string, index) => {
                    return (
                      <div className="form-check" key={index}>
                        <input
                          value="tipologia"
                          className="form-check-input"
                          type="checkbox"
                          id={`${nomeTipologia}-${index}`}
                          checked={struttureSelezionate.includes(struttura)}
                          onChange={(event) => {
                            manageStrutturaSelezionata(struttura, event);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`${nomeTipologia}-${index}`}
                        >
                          {struttura}
                        </label>
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </div>

        <div className="row">
          <h3>Inventario fonetico</h3>

          <div className="col">
            <h4>Vocali</h4>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                selezionaTutteVocali();
              }}
            >
              Seleziona tutti
            </button>
          </div>
          <div className="d-flex flex-row justify-content-between">
            {vocali.map((fonema, index) => {
              return (
                <div className="form-check" key={index}>
                  <input
                    value={fonema}
                    className="form-check-input"
                    type="checkbox"
                    id={`${fonema}-fonema`}
                    checked={fonemiSelezionati.includes(fonema)}
                    onChange={(event) => {
                      manageFonemaSelezionato(fonema, event);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`${fonema}-fonema`}
                  >
                    {fonema}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <br />

        <div className="row">
          <h4>Consonanti</h4>
          <table className="table table-bordered table-sm table-responsive bg-white">
            <thead>
              <tr>
                <th scope="col">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    #
                  </div>
                </th>

                {Object.keys(consonanti.occlusive).map(function (
                  tipologia,
                  index
                ) {
                  return (
                    <th scope="col" key={index}>
                      <div
                        className="d-flex flex-column justify-content-around align-items-center"
                        style={{ gap: "5px", fontFamily: "Neucha" }}
                      >
                        {tipologia}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          style={{
                            fontSize: "0.6em",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => {
                            selezionaTuttaTipologia(tipologia);
                          }}
                        >
                          Seleziona tutti
                        </button>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.keys(consonanti).map(function (
                tipologiaPronuncia,
                index
              ) {
                return (
                  <tr key={index}>
                    <th scope="row">
                      <div
                        className="d-flex justify-content-around align-items-center"
                        style={{ gap: "5px", fontFamily: "Neucha" }}
                      >
                        {tipologiaPronuncia}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          style={{
                            fontSize: "0.6em",
                            padding: "5px",
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => {
                            selezionaTutteTipologiaPronuncia(
                              tipologiaPronuncia
                            );
                          }}
                        >
                          Seleziona tutti
                        </button>
                      </div>
                    </th>

                    {
                      // @ts-ignore
                      Object.keys(consonanti[tipologiaPronuncia]).map(function (
                        tipologiaArticolatoria,
                        index
                      ) {
                        return (
                          <td key={index}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              {
                                // @ts-ignore
                                consonanti[tipologiaPronuncia][
                                  tipologiaArticolatoria
                                ].map((consonante: string, index: number) => {
                                  return (
                                    <div key={index} className="form-check">
                                      <input
                                        value={consonante}
                                        className="form-check-input"
                                        type="checkbox"
                                        // @ts-ignore
                                        id={`${consonante}-fonema`}
                                        checked={fonemiSelezionati.includes(
                                          consonante
                                        )}
                                        onChange={(event) => {
                                          manageFonemaSelezionato(
                                            consonante,
                                            event
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        // @ts-ignore
                                        htmlFor={`${consonante}-fonema`}
                                      >
                                        {consonante}
                                      </label>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <h3>Parole</h3>
          <div className="col">
            <div className="">
              <label htmlFor="includiParole" style={{ display: "inline" }}>
                Usa
              </label>{" "}
              <select
                id="includiParole"
                value={tipologiaParole}
                onChange={(event) => {
                  aggiornaTipologiaParole(parseInt(event.target.value));
                }}
                style={{ display: "inline" }}
              >
                <option value="0">Parole</option>
                <option value="1">Non parole</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-check">
              <input
                id="includiGeminate"
                className="form-check-input"
                type="checkbox"
                checked={includiGeminate}
                onChange={() => {
                  aggiornaIncludiGeminate(!includiGeminate);
                }}
              />
              <label className="form-check-label" htmlFor="includiGeminate">
                Includi geminate
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <h3>Esercitazione</h3>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="eserciziCorretti" className="form-label">
                Numero di esercizi da completare correttamente:
              </label>
              <input
                type="number"
                min="1"
                value={eserciziDaCompletare}
                className="form-control"
                id="eserciziCorretti"
                onChange={(event) => {
                  aggiornaEserciziDaCompletare(parseInt(event.target.value));
                }}
              />
            </div>
          </div>

          <div className="mb-3">
            <h4 className="form-label">Sfondo:</h4>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
              }}
            >
              {[
                "nuvole.jpg",
                "fattoria.png",
                "giungla.jpg",
                "mare.jpg",
                "natale.jpg",
                "pasqua.jpg",
                "rosa.jpg",
                "spazio.jpg",
              ].map(function (img) {
                return (
                  <div
                    key={img}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <label className="form-check-label" htmlFor={img}>
                      <img
                        src={`/images/training-backgrounds/${img}`}
                        width="200"
                      />
                    </label>

                    <input
                      id={img}
                      className="form-check-input"
                      type="radio"
                      name="sfondo"
                      value={img}
                      checked={img === sfondo}
                      onChange={() => {
                        aggiornaSfondo(img);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row align-items-right py-4">
          <div
            className="col d-flex justify-content-end"
            style={{ gap: "20px" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <b className="text-danger">{errore}</b>
            </div>
            <button
              type="button"
              className="btn btn-primary bg-primary"
              style={{ fontSize: "1.5em" }}
              onClick={checkAndSubmit}
            >
              Conferma e Avvia
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
