import React from "react";
import ReactDOM from "react-dom";

// Librerie
import "./lib/bootstrap";
import "./lib/papercss";
import "animate.css";

// Stile
import "./assets/sass/main.scss";

// Applicazione
import App from "./app";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
