import { Howl, Howler } from "howler";

const sounds = {
  correct: new Howl({
    src: "sounds/correct.mp3",
    volume: 0.5,
  }),
  victory: new Howl({
    src: "sounds/victory.mp3",
    volume: 0.5,
  }),
  ambient: new Howl({
    src: "sounds/ambient.mp3",
    loop: true,
    volume: 0.025,
  }),
  ambient_victory: new Howl({
    src: "sounds/ambient_victory.mp3",
    loop: true,
    volume: 0.5,
  }),
};

export function playSound(soundName: keyof typeof sounds) {
  const sound = sounds[soundName];

  sound.play();
}

export function stopSound(soundName: keyof typeof sounds) {
  const sound = sounds[soundName];

  sound.stop();
}

export function stopAllSounds() {
  for (let soundName in sounds) {
    sounds[soundName as keyof typeof sounds].stop();
  }
}
