import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/home";
import NotFoundPage from "./pages/404";
import Setup from "./pages/setup";
import Training from "./pages/training";
import Logo from "./assets/logo.svg?component";

function App() {
  return (
    <div
      className="d-flex flex-column"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <BrowserRouter>
        <Route exact path={["/setup", "/"]}>
          <nav className="navbar" style={{ padding: 0, background: "inherit" }}>
            <div
              className="container-fluid"
              style={{ justifyContent: "center" }}
            >
              <Link to="/" style={{ border: "none" }}>
                <div className="navbar-brand d-flex flex-column align-items-center justify-content-center">
                  <Logo width={120} height={120} />
                  <h1 style={{ margin: 0, fontWeight: "bold" }}>AlFoR</h1>
                </div>
              </Link>
            </div>
          </nav>
        </Route>

        <Switch>
          {/* <Route path="/congratulations" component={Congratulations}></Route> */}
          <Route path="/training" component={Training}></Route>

          <Route path="/setup" component={Setup}></Route>
          <Route exact={true} component={Home}></Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
