// @ts-ignore
import sillabazione from "../../data/fonotassi/sillabazione.json";
import { TrainingData } from "../../training";
import { DatabaseRaw, Fonema, Ortografia } from "../index";
import { NonParola, Parola } from "./Parola";
import { Sillaba } from "./Sillaba";

export class Database {
  parole: Parola[];

  sillabeUsate: Set<Sillaba>;

  constructor(databaseRaw: DatabaseRaw) {
    this.parole = databaseRaw.map(function (parolaRaw) {
      return new Parola(...parolaRaw);
    });

    // Recupero sillabe usate nel database
    this.sillabeUsate = new Set();
    this.parole.forEach((parola) => {
      parola.sillabe.forEach((sillaba) => {
        this.sillabeUsate.add(sillaba);
      });
    });
  }

  queryParole(trainingData: TrainingData) {
    const { fonemiSelezionati, struttureSelezionate, includiGeminate } =
      trainingData;

    return this.parole.filter(function (parola) {
      // CONTROLLO STRUTTURA
      // Si controlla l'esattezza della struttura
      if (!struttureSelezionate.includes(parola.struttura)) {
        return false;
      }

      // CONTROLLO FONEMA
      const caratteriFonologia = parola.caratteriFonologia;

      for (let i = 0; i < caratteriFonologia.length; i++) {
        let simbolo = `${caratteriFonologia[i]}`;
        let prossimoSimbolo = caratteriFonologia[i + 1]
          ? `${caratteriFonologia[i + 1]}`
          : null;

        // Controllo geminate
        if (simbolo == ":") {
          if (includiGeminate) {
            continue;
          } else {
            return false;
          }
        }

        /**
         * Controllo affricate
         *
         * "dz"
         * "dʒ"
         * "ts"
         * "tʃ"
         */
        if ((simbolo == "t" || simbolo == "d") && prossimoSimbolo) {
          if (
            (simbolo == "t" &&
              (prossimoSimbolo == "s" || prossimoSimbolo == "ʃ")) ||
            (simbolo == "d" &&
              (prossimoSimbolo == "z" || prossimoSimbolo == "ʒ"))
          ) {
            simbolo += prossimoSimbolo;
            i++; // salta il prossimo carattere
          }
        }

        if (!fonemiSelezionati.includes(simbolo)) {
          return false;
        }
      }

      return true;
    });
  }

  generaNonParola(trainingData: TrainingData) {
    const { struttureSelezionate, fonemiSelezionati, includiGeminate } =
      trainingData;

    // Filtro i fonemi dalle regole di fonotassi sulla base dei fonemi selezionati durante la configurazione
    const fonemiUtilizzabili: any = {};

    for (let [tipoParteSillaba, parteSillaba] of Object.entries(sillabazione)) {
      for (let [fonema, trascrizione] of Object.entries(parteSillaba)) {
        if (!trascrizione) {
          // trascrivo automaticamente i fonemi mancanti a partire dalle consonanti base
          trascrizione = trascriviDaC(fonema);
        }

        // Si controlla che i fonemi e gli accordi consonantici usati per generare le non parole
        // siano composti ESCLUSIVAMENTE da fonemi che sono stati passati durante la configurazione

        let partiDelFonemaDaControllare: string[]; // divido il fonema in tutte le sue componenti

        // controllo affricate
        if (tipoParteSillaba === "attacco_c" && fonema.length > 1) {
          partiDelFonemaDaControllare = [fonema];
        } else {
          partiDelFonemaDaControllare = fonema.split("");
        }

        if (
          partiDelFonemaDaControllare.every((p) =>
            fonemiSelezionati.includes(p)
          )
        ) {
          if (!fonemiUtilizzabili[tipoParteSillaba]) {
            fonemiUtilizzabili[tipoParteSillaba] = {};
          }
          fonemiUtilizzabili[tipoParteSillaba][fonema] = trascrizione;
        }
      }
    }

    return new NonParola(
      struttureSelezionate,
      fonemiUtilizzabili,
      includiGeminate
    );
  }
}

function trascriviDaC(fonema: string): string {
  const { attacco_c } = sillabazione;

  return fonema
    .split("")
    .map((suono) => {
      // @ts-ignore
      return attacco_c[suono];
    })
    .join("");
}
