export enum Tipologia {
  sillabe = "sillabe",
  bisillabe = "bisillabe",
  trisillabe = "trisillabe",
}

export interface SetStrutture {
  [Tipologia.sillabe]: string[];
  [Tipologia.bisillabe]: string[];
  [Tipologia.trisillabe]: string[];
}

let strutture: SetStrutture;

strutture = {
  sillabe: ["CV", "CVV", "CVC", "VC", "CCV", "CCCV"],
  bisillabe: [
    "CV-CV",
    "CCV-CV",
    "CVV-CV",
    "CV-V",
    "CVC-CV",
    // "CV-C",
    "V-CV",
    "CV-CCV",
    "CVC-CVV",
  ],
  trisillabe: [
    "CV-CV-CV",
    "CV-CVC-CV",
    "V-CV-CV",
    "CV-CVV-CV",
    "CVC-CV-CV",
    "VC-CV-CV",
    "CCV-CV-CV",
    "CVC-CVC-CV",
  ],
};

export default strutture;
