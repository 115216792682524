import React, { FunctionComponent, useEffect, useState } from "react";

const loginUrl = "https://auth.arasaac.org/oauth/token";
const bestSearchUrl = "https://api.arasaac.org/api/pictograms/it/bestsearch/";
const pictogramUrl = "https://api.arasaac.org/api/pictograms/";

async function bestSearch(parola: string, lang: string = "it") {
  return new Promise((resolve) => {
    fetch(
      `https://api.arasaac.org/api/pictograms/${lang}/bestsearch/${parola}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => resolve(result[0]));
  });
}

async function getPictogram(id: string) {
  return new Promise((resolve) => {
    fetch(`https://api.arasaac.org/api/pictograms/${id}?download=false`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((result) => resolve(result));
  });
}

const pecs: FunctionComponent<{ parola: string }> = ({ parola }) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>();

  useEffect(() => {
    bestSearch(parola).then(async (risultato: any) => {
      setImgUrl(
        `https://api.arasaac.org/api/pictograms/${risultato._id}?download=false`
      );
    });
  }, []);

  return (
    <div>
      <img src={imgUrl}></img>
    </div>
  );
};

export default pecs;
