export function randomItem(array: any[]) {
  return array[Math.floor(Math.random() * array.length)];
}

export function isEmptyObject(obj: Object) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}
