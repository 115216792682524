export interface TrainingData {
  id: string;
  created: number;

  struttureSelezionate: string[];
  fonemiSelezionati: string[];

  tipologiaParole: number; // 0 Parole, 1 Non parole
  includiGeminate: boolean;

  sfondo: string;

  eserciziDaCompletare: number;
  eserciziCompletati: number;
}

//export const currentTrainingData: Ref<TrainingData | null> = ref(null);
/* const [currentTrainingData, setCurrentTrainingData] =
  useState<TrainingData | null>();

export { currentTrainingData }; */

export function salvaTraining(trainingData: TrainingData) {
  window.localStorage.setItem(
    `training_data_${trainingData.id}`,
    JSON.stringify(trainingData)
  );
}

export function recuperaTraining(trainingId: string) {
  const retrieveRawData = window.localStorage.getItem(
    `training_data_${trainingId}`
  );

  if (!retrieveRawData) {
    throw new Error("training_non_salvato");
  }

  return JSON.parse(retrieveRawData);
}

export function aggiungiPunti(trainingId: string, puntiDaAggiungere: number) {
  let trainingData = recuperaTraining(trainingId);

  trainingData.eserciziCompletati += puntiDaAggiungere;

  salvaTraining(trainingData);
}

export function impostaPunti(trainingId: string, puntiDaImpostare: number) {
  let trainingData = recuperaTraining(trainingId);

  trainingData.eserciziCompletati = puntiDaImpostare;

  salvaTraining(trainingData);
}
