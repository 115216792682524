import React, { FunctionComponent, memo, useMemo, useState } from "react";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";

const bounceAnimation = keyframes`${bounce}`;

const AnimaleAnimato = styled.img`
  position: relative;
  bottom: -12%;

  width: 100%;

  animation: 1s ${bounceAnimation};
`;

export const randomAnimalImagePath = () => {
  const animaliDisponibili = ["Cat", "Chick", "Fox", "Mouse", "Pig", "Rabbit"];

  const animaleCasuale =
    animaliDisponibili[Math.floor(Math.random() * animaliDisponibili.length)];

  return `/images/animals/${animaleCasuale}.png`;
};

const RandomAnimal: FunctionComponent<{ nome: string; onClick?: () => any }> =
  ({ nome, onClick }) => {
    const [state, setState] = useState<{ nome: string }>({ nome });

    const imagePath = useMemo(() => {
      return randomAnimalImagePath();
    }, [state.nome]);

    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        onClick={onClick}
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
      >
        <AnimaleAnimato src={imagePath} />
        <pre
          className="fs-1 border border-primary text-center text-uppercase"
          style={{
            margin: 0,
            padding: "5px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <span>{nome}</span>
        </pre>
      </div>
    );
  };

export default memo(RandomAnimal);
