// @ts-ignore
import ConfettiGenerator from "confetti-js";
import React, { useEffect } from "react";
import { ArrowClockwise, Plus, PlusLg } from "react-bootstrap-icons";
import { Link, Redirect, useLocation } from "react-router-dom";
import { impostaPunti, recuperaTraining, TrainingData } from "..";
import { playSound, stopAllSounds } from "../../audio";

function Congratulations() {
  const id = new URLSearchParams(useLocation().search).get("id");

  if (!id) {
    throw new Error("Id non presente");
  }

  const currentTrainingData: TrainingData = recuperaTraining(id.toString());

  useEffect(() => {
    const confetti = new ConfettiGenerator({
      target: "confettiCanvas",
      max: 120,
      size: 1.25,
      clock: 45,
    });

    confetti.render();

    return () => confetti.clear();
  }, []);

  function resetCurrentTrainingCount() {
    impostaPunti(currentTrainingData.id, 0);

    stopAllSounds();

    playSound("ambient");
  }

  return (
    <div className="container p-4 d-flex flex-column align-items-center justify-content-center h-100">
      <canvas
        id="confettiCanvas"
        className="position-absolute"
        style={{
          background: "url('/images/victory_background.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          zIndex: -10,
        }}
      ></canvas>

      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ flex: 1 }}
      >
        <div
          className="text-center animate__animated animate__bounceIn"
          style={{ width: "16em", userSelect: "none" }}
        >
          <img
            src="/images/trophy.png"
            style={{
              display: "block",
              width: "100%",
              filter: "drop-shadow(0 0 8px #ffe960)",
            }}
          />
        </div>
      </div>

      <div className="w-100 d-flex justify-content-around" style={{ flex: 0 }}>
        <Link
          to={`/training?id=${currentTrainingData.id}`}
          onClick={resetCurrentTrainingCount}
        >
          <button
            type="button"
            className="paper-btn btn-primary"
            style={{ fontSize: "1.5em" }}
          >
            <ArrowClockwise size="1.5em" />
            Ripeti training
          </button>
        </Link>

        <Link to="/setup" onClick={stopAllSounds}>
          <button
            type="button"
            className="paper-btn btn-primary bg-primary"
            style={{ fontSize: "1.5em" }}
          >
            <Plus size="1.5em" />
            Nuovo training
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Congratulations;
