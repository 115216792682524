import { randomItem } from "../../common/utils";
import {
  Fonema,
  Fonologia,
  Ortografia,
  ParolaSillabata,
  Struttura,
} from "../index";
import { Sillaba, SillabaCasuale } from "./Sillaba";
import regole_accordo from "../../data/fonotassi/regole_accordo.json";

export class Parola {
  parolaSillabata: ParolaSillabata;
  fonologia: Fonologia;
  struttura: Struttura;

  testo: string;
  caratteriFonologia: string[];

  sillabe: Sillaba[];

  constructor(
    parolaSillabata: ParolaSillabata,
    fonologia: Fonologia,
    struttura: Struttura
  ) {
    this.parolaSillabata = parolaSillabata;
    this.fonologia = fonologia;
    this.struttura = struttura;

    // Tutte le sillabe in forma testuale della parola
    const sillabeRaw = this.parolaSillabata.split("-");

    // Unisco tutte le sillabe per avere il testo della parola
    this.testo = sillabeRaw.join("");

    // Separo la fonologia in tutti i suoi caratteri
    // TODO controllo affricate e geminate
    this.caratteriFonologia = this.fonologia.split("");

    // Recupero tutte le strutture della parola
    const struttureSillabeRaw = this.struttura.split("-");

    this.sillabe = [];
    for (let i = 0; i < sillabeRaw.length; i++) {
      this.sillabe[i] = new Sillaba(
        sillabeRaw[i],
        "___",
        struttureSillabeRaw[i]
      );
    }
  }
}

function controllaAccordoSillabe(
  sillabe: Sillaba[],
  permettiGeminate: boolean = false
) {
  // Per ogni sillaba controllo che la sillaba successiva risponda alle regolo di accordo
  for (let i = 0; i < sillabe.length; i++) {
    const sillaba = sillabe[i];
    const sillabaSucc = sillabe[i + 1];

    if (sillabaSucc) {
      // Se la sillaba ha una coda e la sillaba successiva ha un attacco
      if (sillaba.coda && sillabaSucc.attacco) {
        // Se non esiste una regola di accordo per quella coda, l'accordo non è sicuramente valido
        if (!regole_accordo[sillaba.coda[0] as keyof typeof regole_accordo]) {
          return false;
        }

        if (
          // Se tra le regole di accordo NON è presente l'attacco della sillaba successiva...
          !regole_accordo[
            sillaba.coda[0] as keyof typeof regole_accordo
          ].includes(sillabaSucc.attacco[0]) &&
          // ...e escluso il caso della geminazione...
          !(permettiGeminate && sillabaSucc.attacco[1] === sillaba.coda[1])
        ) {
          // ...la sillaba non è valida!
          return false;
        }
      }
    }
  }

  return true;
}

export class NonParola extends Parola {
  constructor(
    struttureDaUtilizzare: Struttura[],
    fonemiDaUtilizzare: any,
    includiGeminate: boolean
  ) {
    // Recupero una struttura a caso tra quelle selezionate da utilizzare
    const strutturaSillabata: Struttura = randomItem(struttureDaUtilizzare);

    // Creo una sillaba casuale a partire da quella struttura
    let sillabe: Sillaba[];

    do {
      sillabe = strutturaSillabata
        .split("-")
        .map((strutturaSillabica: Struttura) => {
          return new SillabaCasuale(strutturaSillabica, fonemiDaUtilizzare);
        });
    } while (!controllaAccordoSillabe(sillabe, includiGeminate));

    const parolaSillabata = sillabe.map((s) => s.ortografia).join("-");
    const fonologia = sillabe.map((s) => s.fonologia).join("");
    const struttura = sillabe.map((s) => s.struttura).join("-");

    super(parolaSillabata, fonologia, struttura);
  }
}
