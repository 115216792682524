import React from "react";

export default function NotFoundPage() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100">
      <p>Error 404 - not Found</p>
      <img src="../assets/404.svg" />
    </div>
  );
}
