// @ts-ignore
import Papa from "papaparse";
import { randomItem } from "../common/utils";
import rawDatabase from "../data/database.csv?raw";
import { TrainingData } from "../training";
import { Database } from "./classes/Database";
import { Parola } from "./classes/Parola";

export type ParolaSillabata = string;
export type Fonologia = string;
export type Struttura = string;
export type Fonema = string;
export type Ortografia = string;

export type ParolaRaw = [ParolaSillabata, Fonologia, Struttura];
export type DatabaseRaw = ParolaRaw[];

export const databaseRaw: DatabaseRaw = Papa.parse(rawDatabase).data;
export const database = new Database(databaseRaw);

export function parolaPerTraining(trainingData: TrainingData): Parola | null {
  const { tipologiaParole } = trainingData;

  let parola: Parola;

  if (tipologiaParole === 0) {
    parola = randomItem(database.queryParole(trainingData));
  } else if (tipologiaParole === 1) {
    parola = database.generaNonParola(trainingData);
  } else {
    throw new Error("Caso non gestito");
  }

  return parola ?? null;
}
