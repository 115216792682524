const synth = window.speechSynthesis;

export function getVoiceList() {
  const voices = synth.getVoices().sort(function (a, b) {
    const aname = a.name.toUpperCase(),
      bname = b.name.toUpperCase();
    if (aname < bname) return -1;
    else if (aname == bname) return 0;
    else return +1;
  });

  return voices;
}

interface SpeakConfig {
  text: string;
  voice?: SpeechSynthesisVoice;
  rate?: number;
  pitch?: number;
  volume?: number;
}

export const speak = (speakConfig: SpeakConfig) => {
  const utterance = new window.SpeechSynthesisUtterance();
  utterance.text = speakConfig.text;
  utterance.voice = speakConfig.voice ?? null;
  utterance.rate = speakConfig.rate ?? 0.8;
  utterance.pitch = speakConfig.pitch ?? 1;
  utterance.volume = speakConfig.volume ?? 1;

  window.speechSynthesis.speak(utterance);
};
