// @ts-ignore
import React from "react";
import { ArrowLeft, Back } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import { recuperaTraining, TrainingData } from "..";
import { stopAllSounds } from "../../audio";

function NotFound() {
  const id = new URLSearchParams(useLocation().search).get("id");

  if (!id) {
    throw new Error("Id non presente");
  }

  const currentTrainingData: TrainingData = recuperaTraining(id.toString());

  stopAllSounds();

  return (
    <div className="container p-4 d-flex flex-column align-items-center justify-content-center h-100">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ flex: 1 }}
      >
        <div
          className="text-center animate__animated animate__bounceIn"
          style={{ width: "16em", userSelect: "none" }}
        >
          {/* <img
            src="/images/trophy.png"
            style={{
              display: "block",
              width: "100%",
              filter: "drop-shadow(0 0 8px #ffe960)",
            }}
          /> */}

          <h3>Nessuna corrispondenza nel database</h3>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-around" style={{ flex: 0 }}>
        <Link to="/setup" onClick={stopAllSounds}>
          <button
            type="button"
            className="paper-btn btn-primary bg-primary"
            style={{ fontSize: "1.5em" }}
          >
            <ArrowLeft size="1.5em" /> Cambia configurazione
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
