import React from "react";
import { FunctionComponent } from "react";

const loadingBar: FunctionComponent<{ lunghezza: number; completato: number }> =
  ({ lunghezza, completato }) => {
    let percentuale = Math.round((completato / lunghezza) * 100);

    return (
      <div className="progress">
        <div className={`bar striped secondary w-${percentuale}`}></div>
      </div>
    );
  };

export default loadingBar;
