import React from "react";
import { Plus } from "react-bootstrap-icons";
import { Route, Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="container d-flex flex-column h-100">
      <main className="container">
        <div className="row">
          <div className="col-lg text-center">
            <h2>Training fonetico alla portata di tutti</h2>
            <p className="lead">
              Sviluppa e amplia le abilità fonetico-fonologiche in modo facile e
              divertente
            </p>

            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <Link to="/setup">
                <button
                  type="button"
                  className="paper-btn btn-primary bg-primary"
                  style={{ fontSize: "1.5em" }}
                >
                  <Plus size="1.5em" />
                  Nuovo training
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <footer className="mt-auto py-4">
        <hr />

        <p className="mb-0 text-muted fw-light">
          Ideato da Sonia Tambellini
          <br />
          Sviluppato da Stefano Tambellini
        </p>
      </footer>
    </div>
  );
}
