import { randomItem } from "../../common/utils";
import attacco_cv from "../../data/fonotassi/attacco_cv.json";
import dittongo from "../../data/fonotassi/dittongo.json";
import { Fonologia, Ortografia, Struttura } from "../index";

const separatore = "/***/";

export class Sillaba {
  ortografia: Ortografia;
  struttura: Struttura;
  fonologia: Fonologia;

  attacco: string | undefined;
  nucleo: string | undefined;
  coda: string | undefined;

  constructor(
    ortografia: Ortografia,
    fonologia: Fonologia,
    struttura: Struttura,
    attacco?: string,
    nucleo?: string,
    coda?: string
  ) {
    this.ortografia = ortografia;
    this.struttura = struttura;
    this.fonologia = fonologia;

    this.attacco = attacco;
    this.nucleo = nucleo;
    this.coda = coda;
  }
}

export class SillabaCasuale extends Sillaba {
  constructor(strutturaSillabica: Struttura, fonemiDaUtilizzare: any) {
    let attacco = null;
    let nucleo = null;
    let coda = null;

    let strutturaNucleo = null;
    // gestione dittongo
    if (strutturaSillabica === "CVV") {
      nucleo = randomItem(Object.entries(dittongo));

      const secondaV = randomItem(Object.entries(fonemiDaUtilizzare.nucleo));
      nucleo[0] = nucleo[0].replace("V", secondaV[0]);
      nucleo[1] = `${nucleo[1]}${secondaV[1]}`;
      strutturaNucleo = "VV";
    } else {
      nucleo = randomItem(Object.entries(fonemiDaUtilizzare.nucleo));
      strutturaNucleo = "V";
    }

    // Genero un'ortografia sottoforma di array
    // Il risultato è un array sempre di 3 elementi il quale primo è l'attacco, il secondo il nucleo e il terzo la coda
    // Se l'attacco o la coda non sono presenti, viene sostituito con null
    const ortografiaArr = strutturaSillabica
      .replace(strutturaNucleo, `${separatore}${nucleo[1]}${separatore}`)
      .split(separatore)
      .map((v) => (v ? v : null));

    const [_attacco, _nucleo, _coda] = ortografiaArr;

    if (nucleo[1] !== _nucleo) {
      throw new Error("Errore separazione, cambiare valore separatore");
    }

    // !!! Se le forme consonantiche non sono supportate dalla lingua in uso, la consonante viene piallata via, da considerare una migliore gestione

    if (_attacco) {
      if (_attacco === "C" && fonemiDaUtilizzare.attacco_c) {
        attacco = randomItem(Object.entries(fonemiDaUtilizzare.attacco_c));
      }

      if (_attacco === "CC" && fonemiDaUtilizzare.attacco_cc) {
        attacco = randomItem(Object.entries(fonemiDaUtilizzare.attacco_cc));
      }

      if (_attacco === "CCC" && fonemiDaUtilizzare.attacco_ccc) {
        attacco = randomItem(Object.entries(fonemiDaUtilizzare.attacco_ccc));
      }
    }

    if (_coda) {
      if (_coda === "C" && fonemiDaUtilizzare.coda) {
        coda = randomItem(Object.entries(fonemiDaUtilizzare.coda));
      }
    }

    let ortografia = [
      attacco ? attacco[1] : null,
      nucleo ? nucleo[1] : null,
      coda ? coda[1] : null,
    ].join("");

    const fonologia = [
      attacco ? attacco[0] : null,
      nucleo ? nucleo[0] : null,
      coda ? coda[0] : null,
    ].join("");

    // Se la fonologia include almeno una forma tra quelle elencate in attacco_cv
    // sostituisco la relativa ortografia per come è stato trascritto
    // !!! ATTENZIONE !!!
    // !!! Questo crea un inconsistenza tra l'attacco nell'ortografia e l'attacco effettivamente passato al costruttore della sillaba
    // !!! Questo funziona perchè in italiano questo cambiamento non provoca grandi differenze per il controllo degli accordi tra sillabe (che il caso critico)

    for (let fonologiaAttaccoCV in attacco_cv) {
      if (fonologia.includes(fonologiaAttaccoCV)) {
        const [vecchio, nuovo] =
          attacco_cv[fonologiaAttaccoCV as keyof typeof attacco_cv];

        ortografia = ortografia.replace(vecchio, nuovo);
        break;
      }
    }

    super(ortografia, fonologia, strutturaSillabica, attacco, nucleo, coda);
  }
}
