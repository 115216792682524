import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { playSound, stopSound } from "../audio";
import { parolaPerTraining } from "../database";
import { speak } from "../speech";
import { aggiungiPunti, recuperaTraining, TrainingData } from "../training";
import Congratulations from "../training/components/congratulations";
import LoadingBar from "../training/components/loadingBar";
import NotFound from "../training/components/notFound";
import Pecs from "../training/components/pecs";
import RandomAnimal from "../training/components/randomAnimal";

const PecsModal = forwardRef(function ({ testo }: { testo: string }, ref) {
  const [showPecsModal, setShowShowPecsModal] = useState(false);

  useImperativeHandle(ref, () => ({
    setShowShowPecsModal,
  }));

  return showPecsModal ? (
    <div style={{ position: "absolute", top: 0, right: 0 }}>
      <button
        type="button"
        onClick={() => {
          setShowShowPecsModal(false);
        }}
      >
        X
      </button>

      <Pecs parola={testo} />
    </div>
  ) : (
    <div></div>
  );
});

export default function Training() {
  const id = new URLSearchParams(useLocation().search).get("id");

  if (!id) {
    throw new Error("Id non presente");
  }

  const currentTrainingData: TrainingData = recuperaTraining(id.toString());

  /*   if (
    currentTrainingData.eserciziCompletati >=
    currentTrainingData.eserciziDaCompletare
  ) {
    return <CongratulationsPage />;
  } */

  // ###

  const trainingData = currentTrainingData;

  let corrispondenza = parolaPerTraining(trainingData);

  const [currentState, setState] = useState({
    trainingData,
    corrispondenza,
  });

  const corrispondenzaCasuale = corrispondenza;

  if (corrispondenzaCasuale) {
    useEffect(() => {
      stopSound("ambient");
      stopSound("ambient_victory");

      if (
        currentTrainingData.eserciziCompletati >=
        currentTrainingData.eserciziDaCompletare
      ) {
        playSound("ambient_victory");
      } else {
        playSound("ambient");
      }
    }, []);

    useEffect(() => {
      if (
        currentTrainingData.eserciziCompletati >=
        currentTrainingData.eserciziDaCompletare
      ) {
        //
      } else {
        speak({ text: corrispondenzaCasuale.testo });
      }
    });
  }

  function reload() {
    setState((oldState) => {
      corrispondenza = parolaPerTraining(trainingData);

      return { ...oldState, corrispondenza };
    });
  }

  function segnaCorretto() {
    aggiungiPunti(trainingData.id, 1);

    if (
      currentTrainingData.eserciziCompletati + 1 >=
      currentTrainingData.eserciziDaCompletare
    ) {
      stopSound("ambient");
      playSound("victory");
      playSound("ambient_victory");
    } else {
      playSound("correct");
    }

    reload();
  }

  function segnaErrato() {
    reload();
  }

  const pecsModalRef = useRef();

  return currentTrainingData.eserciziCompletati >=
    currentTrainingData.eserciziDaCompletare ? (
    <Congratulations />
  ) : corrispondenzaCasuale ? (
    <div
      className="d-flex flex-column align-items-center h-100"
      style={{
        backgroundImage: `url("/images/training-backgrounds/${currentTrainingData.sfondo}")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <PecsModal
        ref={pecsModalRef}
        testo={corrispondenzaCasuale.testo}
      ></PecsModal>

      <div style={{ flex: 0 }}>
        <div className="p-2">
          <pre
            className="text-center fs-4 border border-primary"
            style={{ padding: "10px" }}
          >
            <span>
              {corrispondenzaCasuale.testo} -{" "}
              {corrispondenzaCasuale.parolaSillabata} -{" "}
              {corrispondenzaCasuale.fonologia}
            </span>
          </pre>
        </div>
      </div>

      <div
        className="w-100 position-relative d-flex align-items-center justify-content-center"
        style={{ flex: 1 }}
      >
        <div
          className="height-100 d-flex flex-nowrap"
          style={{ maxWidth: "100%", userSelect: "none" }}
        >
          {corrispondenzaCasuale.sillabe.map(function (sillaba) {
            return (
              <div key={Math.random() * 10} className="col" style={{ flex: 1 }}>
                <RandomAnimal
                  nome={sillaba.ortografia}
                  onClick={() => speak({ text: sillaba.ortografia })}
                ></RandomAnimal>
              </div>
            );
          })}
        </div>
      </div>

      <div className="py-2" style={{ flex: 0, width: "50%" }}>
        <LoadingBar
          lunghezza={trainingData.eserciziDaCompletare}
          completato={trainingData.eserciziCompletati}
        />
      </div>

      <div id="buttonBar" style={{ flex: 0 }}>
        <div className="text-center">
          <div className="p-2 btn-group" role="group">
            <button
              type="button"
              className="btn btn-success fs-4"
              onClick={() => {
                // @ts-ignore
                pecsModalRef.current.setShowShowPecsModal(false);
                segnaCorretto();
              }}
            >
              Corretto
            </button>

            <button
              type="button"
              className="btn btn-primary fs-4"
              onClick={() => {
                // @ts-ignore
                pecsModalRef.current.setShowShowPecsModal(true);
              }}
            >
              (?)
            </button>

            <button
              type="button"
              className="btn btn-danger fs-4"
              onClick={() => {
                // @ts-ignore
                pecsModalRef.current.setShowShowPecsModal(false);
                segnaErrato();
              }}
            >
              Errato
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NotFound />
  );
}
